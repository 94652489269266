<template>
    <OMediaQueryProvider v-slot="{ isTouch, xs }">
        <button 
            class="btn"
            :class="isTouch ? 'btn-primary btn-touch' : 'btn-link btn-sm'"
            @click="openModal">
            {{$t('Choose Fields')}}
        </button>
        
        <OModal :title="$t('Choose Filter Fields')" ref="modalRef" class="modal-lg" dialogClass="modal-dialog-scrollable">
            <template #headerAction>
                <input v-model="searchValue" ref="searchInput" class="form-control form-control-sm" :class="{ 'align-self-start mt-4 w-100': xs, 'ms-4 w-50': !xs }" :placeholder="$t('Search...')">
            </template>
            <div class="modal-body">
                <div class="w-100 h-100" :style="isTouch ? 'columns:1 auto;' : 'columns:4 auto;'">
                    <div v-for="col in columnList" :key="col.item.field" class="d-flex form-check mb-2 align-items-center">
                        <input :id="col.item.field" class="form-check-input" type="checkbox" :disabled="col.filterItem?.applied" :checked="col.isInList" @change="handleChange($event, col)">
                        
                        <label class="form-check-label w-100 ps-2" :class="{ 'text-muted': col.filterItem?.applied, 'fs-5': isTouch }" :for="col.item.field">
                            {{col.item.caption}}
                        </label>
                    </div>
                </div>
            </div>
        </OModal>
    </OMediaQueryProvider>
</template>

<script setup>
import { ref, computed, onUnmounted } from 'vue';
import OModal from 'o365.vue.components.Modal.vue';
import OMediaQueryProvider from 'o365.vue.components.MediaQueryProvider.vue';

const props = defineProps({
    filterObject: Object
});
const modalRef = ref(null);
const searchValue = ref(null);


const columnList = computed(() => {
    return props.filterObject.columns.reduce((arr, col) => {
            if (col.hideFromList) { return arr; } 
            if (!col.unbound || col.filter) {
                if (searchValue.value) {
                    const searchString = searchValue.value?.toLowerCase();
                    const caption = (col.caption ?? col.name).toLowerCase();
                    if (!caption.includes(searchString)) {
                        return arr;
                    }
                }

                arr.push({
                    item: col,
                    filterItem: props.filterObject.filterItems[col.name],
                    isInList: props.filterObject.fieldFilters.includes(col.name),
                    //disabled: !!props.filterObject._fieldFilters.find(x => x.name===col.field)
                });
            }
        return arr;
    }, []);
}); 

/*
const columnList = computed(() => {
    return props.filterObject.columns.reduce((arr, col) => {
        if (col.filter) {
            arr.push({
                item: col,
                filterItem: props.filterObject.filterItems[col.field],
                isInList: props.filterObject.fieldFilters.includes(col.field),
                //disabled: !!props.filterObject._fieldFilters.find(x => x.name===col.field)
            });
        }
        return arr;
    }, []);
}); 
*/

function handleChange(e, col) {
    if (e.target.checked) {
        props.filterObject.setFieldFilters([col.item.name]);
    } else {
        props.filterObject.removeFieldFilter(col.item.field);
    }
    e.target.blur();
}

let shownAttached = false;
async function openModal() {
    // searchValue.value = null;
    await modalRef.value.show();
    if (!shownAttached) {
        shownAttached = true;
        modalRef.value.modal._element.addEventListener('shown.bs.modal', focusSearchInput)
    }
}

const searchInput = ref(null);
function focusSearchInput() {
    searchInput.value.focus();
}

onUnmounted(() => {
    if (shownAttached && modalRef.value) {
        modalRef.value.modal._element.removeEventListener('shown.bs.modal', focusSearchInput)
    }
});

</script>

<style scoped>
    .btn-touch {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
</style>